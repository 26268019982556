<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl">
            <span>"{{ searchText }}" 검색 결과</span>
          </div>
          <div class="party_list_wrap">
            <div class="list_top result">
              <!--'검색결과 페이지: result'-->
              <div class="left">
                <div class="list_top_ttl">
                  마켓 {{ $Util.formatNumber2(totalCnt, { unit: '' }) }} 건
                </div>
              </div>
              <!--              <div class="right" v-if="marketCnt > 0">
                <button class="li_btn more">더보기</button>
              </div>-->
            </div>
            <market-list
              :row-type="1"
              :items="list"
              v-if="!$Util.isEmpty(list)"
            />
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>마켓 검색 결과가 없습니다.</div>
              </div>
              <button class="btn" @click="fnMarketWrite">마켓 등록하기</button>
            </div>
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice14'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'
import MarketList from '@/components/views/market/list/index.vue'
export default {
  name: 'market-search-index',
  components: {
    MarketList
  },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
